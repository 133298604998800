import React, { Component } from "react";
import { connect } from "react-redux";
import { getOneCompanyById, modifyCompanyDesign, cropImage } from "../../actions/company";
import { Row, Col, Form, Button, message, Upload } from "antd";
import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import reactCSS from "reactcss";
import { GithubPicker } from "react-color";
import ImgCrop from "antd-img-crop";
import { refreshUser } from "../../actions/login";
import AppPreview from "../Preview/appPreview";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import { detect } from "detect-browser";
import CompanyLanguages from "./companylanguages";
import _ from "lodash";
import { getOneOfficeById, modifyOfficeDesign, saveOfficeText } from "../../actions/office";
import Compress from "compress.js";
import { saveCompanyText } from "../../actions/language";

const mapDispatchToProps = (dispatch) => {
  return {
    modifyCompanyDesign: (formData) => dispatch(modifyCompanyDesign(formData)),
    refreshUser: (userId) => dispatch(refreshUser(userId)),
    getOneCompanyById: (companyId) => dispatch(getOneCompanyById(companyId)),
    getOneOfficeById: (officeId) => dispatch(getOneOfficeById(officeId)),
    modifyOfficeDesign: (formData) => dispatch(modifyOfficeDesign(formData)),
    saveCompanyText: (params) => dispatch(saveCompanyText(params)),
    saveOfficeText: (params) => dispatch(saveOfficeText(params)),
    cropImage: (params) => dispatch(cropImage(params))
  };
};

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};
const compress = new Compress();
class ConnectedCompanyDesign extends Component {

  logoCropper = React.createRef();
  backgroundCropper = React.createRef();
  formRef = React.createRef();

  state = {
    primaryColor: "#ffffff",
    secondaryColor: "#ffffff",
    background: [],
    logo: [],
    previewVisible: false,
    company: {},
    browser: "",
    backgroundCropData: {
      image: "",
      rotation: 0,
      cropData: {}
    },
    logoCropData: {
      image: "",
      rotation: 0,
      cropData: {}
    }
  };

  componentDidMount = async () => {
    await this.updateCompany();
    const browser = detect();
    this.setState({ browser: browser.name });
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (
      !_.isEqual(this.props.company[0], prevProps.company[0]) &&
      this.props.status
    ) {
      this.setState({
        company: this.props.company[0],
        primaryColor: this.props.company[0].primaryColor,
      });
    } else if (
      !_.isEqual(this.props.office[0], prevProps.office[0]) &&
      this.props.officeStatus
    ) {
      this.setState({
        company: this.props.office[0],
        primaryColor: this.props.office[0].primaryColor,
      });
    }
  };

  cropImage = async ( cropData ) => {
    await this.props.cropImage( cropData )
  }

  updateCompany = async () => {
    if (this.props.companyId) {
      await this.props.getOneCompanyById(this.props.companyId);
      if (this.props.status) {
        this.setState({
          company: this.props.company[0],
          primaryColor: this.props.company[0].primaryColor,
        });
      }
    } else {
      if (this.props.user.companyId) {
        await this.props.getOneCompanyById(this.props.user.companyId);
        if (this.props.status) {
          this.setState({
            company: this.props.company[0],
            primaryColor: this.props.company[0].primaryColor,
          });
        }
      } else {
        await this.props.getOneOfficeById(this.props.user.officeId);
        if (this.props.officeStatus) {
          this.setState({
            company: this.props.office[0],
            primaryColor: this.props.office[0].primaryColor,
          });
        }
      }
    }
  };

  handleSubmit = async () => {
    const formData = new FormData();
    formData.set(
      "id",
      this.props.companyId ? this.props.companyId : this.props.user.companyId
    );
    formData.set("primaryColor", this.state.primaryColor);
    formData.set("secondaryColor", this.state.secondaryColor);
    formData.append("logo", this.state.logo[0]);
    formData.append("background", this.state.background[0]);
    if ((this.props.companyId !== null && this.props.companyId !== undefined) || (this.props.user.companyId !== null && this.props.user.companyId !== undefined)) {
      await this.props.modifyCompanyDesign(formData);
      if (this.props.saveStatus) {
        message.success(this.props.t("save-success"), 5);

        await this.handleSaveLanguage();
        await this.updateCompany();
        this.props.wizard ? this.props.next() : this.closeModal();
      } else {
        message.error(this.props.t("save-fail"), 5);
      }
    } else {
      formData.set("id", this.props.user.officeId);
      await this.props.modifyOfficeDesign(formData);
      if (this.props.officeSaveStatus) {
        message.success(this.props.t("save-success"), 5);

        await this.handleSaveLanguage();
        await this.updateCompany();
        this.props.wizard ? this.props.next() : this.closeModal();
      } else {
        message.error(this.props.t("save-fail"), 5);
      }
    }
  };

  closeModal = async () => {
    await this.props.refreshUser(this.props.user.id);
    if (this.props.close) {
      this.props.close();
    }
  };

  beforeUploadBackground = (file) => {

    if (file.size / 1024 / 1024 > 5) {
      this.setState({ background: [] });
      message.error(this.props.t("companyadmin-design-toolargefile"));
    } else {
      const image = new Image();
      image.onload = () => {
        if (image.width > 2000 || image.height > 2000) {
          this.resizeTooBigFile(file);
        } else if (image.width < 1000 || image.height < 1000) {
          this.resizeTooSmallFile(file);
        } else {
          this.setState({ background: [file] });
        }
      };
      image.src = URL.createObjectURL(file);
    }
    return false;
  };

  resizeTooBigFile = async (file) => {
    const resizedImage = await compress.compress([file], {
      size: 5,
      quality: 1,
      maxWidth: 2000,
      maxHeight: 2000,
      resize: true,
    });
    const img = resizedImage[0];
    const base64str = img.data;
    const imgExt = img.ext;
    const resizedFile = Compress.convertBase64ToFile(base64str, imgExt);
    this.setState({ background: [resizedFile] });
  };

  resizeTooSmallFile = async (file) => {
    const resizedImage = await compress.compress([file], {
      size: 5,
      quality: 1,
      minWidth: 1000,
      minHeight: 1000,
      resize: true,
    });
    const img = resizedImage[0];
    const base64str = img.data;
    const imgExt = img.ext;
    const resizedFile = Compress.convertBase64ToFile(base64str, imgExt);
    this.setState({ background: [resizedFile] });
  };

  resizeTooBigLogoFile = async (file) => {
    const resizedImage = await compress.compress([file], {
      size: 5,
      quality: 1,
      maxWidth: 2000,
      maxHeight: 2000,
      resize: true,
    });
    const img = resizedImage[0];
    const base64str = img.data;
    const imgExt = img.ext;
    const resizedFile = Compress.convertBase64ToFile(base64str, imgExt);
    this.setState({ logo: [resizedFile] });
  };

  resizeTooSmallLogoFile = async (file) => {
    const resizedImage = await compress.compress([file], {
      size: 5,
      quality: 1,
      minWidth: 1000,
      minHeight: 1000,
      resize: true,
    });
    const img = resizedImage[0];
    const base64str = img.data;
    const imgExt = img.ext;
    const resizedFile = Compress.convertBase64ToFile(base64str, imgExt);
    this.setState({ logo: [resizedFile] });
  };

  removeFileBackground = () => {
    this.setState({ background: [] });
  };

  beforeUploadLogo = (file) => {

    if (file.size / 1024 / 1024 > 5) {
      this.setState({ logo: [] });
      message.error(this.props.t("companyadmin-design-toolargefile"));
    } else {
      const image = new Image();
      image.onload = () => {
        if (image.naturalWidth / image.naturalHeight === 2) {
          this.setState({ logo: [file] });
        } else {
          if (image.width < 500 || image.height < 500) {
            this.resizeTooSmallLogoFile(file);
          } else if (image.width > 2000 || image.height > 2000) {
            this.resizeTooBigLogoFile(file);
          } else {
            this.setState({ logo: [file] });
          }
        }
      };
      image.src = URL.createObjectURL(file);
    }
    return false;
  };

  removeFileLogo = () => {
    this.setState({ logo: [] });
  };

  onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  handleClickSecondary = () => {
    this.setState({
      displayColorPickerSecondary: !this.state.displayColorPickerSecondary,
    });
  };

  handleCloseSecondary = () => {
    this.setState({ displayColorPickerSecondary: false });
  };

  handleChangeSecondary = (color) => {
    this.setState({ secondaryColor: color.hex });
  };

  handleClickPrimary = () => {
    this.setState({
      displayColorPickerPrimary: !this.state.displayColorPickerPrimary,
    });
  };

  handleClosePrimary = () => {
    this.setState({ displayColorPickerPrimary: false });
  };

  handleChangePrimary = (color) => {
    this.setState({ primaryColor: color.hex });
  };

  openPreview = () => {
    this.setState({ previewVisible: true });
  };

  closePreview = () => {
    this.setState({ previewVisible: false });
  };

  handleSaveLanguage = async () => {
    if(Object.keys(this.props.selectedLanguageText).length !== 0){
      const toSave = {
        texts: this.props.selectedLanguageText,
      };

      if (this.props.officeId !== undefined && this.props.officeId !== null) {
        await this.props.saveOfficeText(toSave);
      } else {
        await this.props.saveCompanyText(toSave);
      }
    }
  };

  getFileName = ( type ) => {
    switch ( type ) {
      case "image/jpeg":
        return "image.jpg"
      case "image/gif":
        return "image.gif"
      default:
        return "image.png"
    }
  }

  render() {
    const stylesPrimary = reactCSS({
      default: {
        color: {
          width: "36px",
          height: "14px",
          borderRadius: "2px",
          background: `${this.state.primaryColor}`,
        },
        swatch: {
          padding: "5px",
          background: "#fff",
          borderRadius: "1px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          display: "inline-block",
          cursor: "pointer",
        },
        popover: {
          position: "absolute",
          zIndex: "2",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });
    return (
      <div>
        <Form
          onFinish={this.handleSubmit}
          ref={this.formRef}
          {...formItemLayout}
          name="design"
          scrollToFirstError
          layout="vertical"
        >
          <Row>
            <Col span={8} style={{ padding: "0.5em" }}>

                <Form.Item
                  label={
                    <strong>
                      {this.props.t("companyadmin-design-theme-label")}:
                    </strong>
                  }
                  name="primaryColor"
                >
                  <div>
                    <div
                      style={stylesPrimary.swatch}
                      onClick={this.handleClickPrimary}
                    >
                      <div style={stylesPrimary.color} />
                    </div>
                    {this.state.displayColorPickerPrimary ? (
                      <div style={stylesPrimary.popover}>
                        <div
                          style={stylesPrimary.cover}
                          onClick={this.handleClosePrimary}
                        />
                        <GithubPicker
                          colors={[
                            "#9E1C5C",
                            "#005245",
                            "#EEAC4D",
                            "#5C2A2E",
                            "#EE84B3",
                            "#187B30",
                            "#00A5E3",
                            "#74737A",
                            "#FFA23A",
                            "#0065A2",
                            "#FF5768",
                            "#00AEA9",
                          ]}
                          color={this.state.primaryColor}
                          onChange={this.handleChangePrimary}
                        />
                      </div>
                    ) : null}
                  </div>
                </Form.Item>
                <Form.Item
                  label={
                    <strong>
                      {this.props.t("companyadmin-design-logo-label")}:
                    </strong>
                  }
                  name="logo"
                >
                  <ImgCrop
                    ref={this.logoCropper}
                    cropperProps={{
                      onCropAreaChange: ( cropArea, croppedAreaPixels ) => {
                        this.setState( { logoCropData: { ...this.state.logoCropData, rotation: 0, cropData: croppedAreaPixels } } )
                      }
                    }}
                    zoom
                    shape="rect"
                    grid
                    aspect={16 / 9}
                    modalOk={
                      <>
                        <SaveOutlined /> {this.props.t("save-button-label")}
                      </>
                    }
                    modalCancel={this.props.t("cancel-button-label")}
                    modalTitle={this.props.t(
                      "companyadmin-design-cropimage-title"
                    )}
                    fillColor={this.props.company[0]?.primaryColor}
                  >
                    <Upload
                      name="logo"
                      listType="picture-card"
                      fileList={this.state.logo}
                      showUploadList={false}
                      onRemove={this.removeFileLogo}
                      accept=".png,.jpg,.jpeg,.gif"
                      onPreview={this.onPreview}
                      // beforeUpload={ () => false }
                      onChange={ async ( evt ) => {
                        console.log("logo upload Onchange event", evt);
                        if (evt.file) {
                          this.setState( { logoCropData: { ...this.state.logoCropData, image: evt.file.originFileObj } } );
                          await this.cropImage( this.state.logoCropData );
                          this.setState( { logo: [ new File( [ this.props.image ], this.getFileName( this.props.image.type ), { type: this.props.image.type } ) ] } );
                        }
                      } }
                    >
                      {this.state.logo.length < 1 ? (
                        !this.state.company.logoName ? (
                          <React.Fragment>
                            <PlusOutlined />
                            <div>{this.props.t("companyadmin-design-upload")}</div>
                          </React.Fragment>
                        ) : (
                          <img
                            src={`${
                              process.env.REACT_APP_BASE_URL
                            }/api/1.0.0/company/logo/${
                              this.state.company.logoName
                            }?token=${
                              this.props.token.split(" ")[1]
                            }&date=${Date.now()}`}
                            alt="logo"
                            width={100}
                          />
                        )
                      ) : (
                        <img
                          src={URL.createObjectURL(this.state.logo[0])}
                          alt="logo"
                          width={100}
                        />
                      )}
                    </Upload>
                  </ImgCrop>
                  <div style={{ color: "red" }}>
                    {this.props.t("companyadmin-design-maxsize-logo")}
                  </div>
                </Form.Item>
                <Form.Item
                  label={
                    <strong>
                      {this.props.t("companyadmin-design-logo-background")}:
                    </strong>
                  }
                  name="background"
                >
                  <ImgCrop
                    ref={this.backgroundCropper}
                    cropperProps={{
                      onCropAreaChange: ( cropArea, croppedAreaPixels ) => {
                        this.setState( { backgroundCropData: { ...this.state.backgroundCropData, rotation: 0, cropData: croppedAreaPixels } } )
                      }
                    }}
                    zoom
                    shape="rect"
                    grid
                    aspect={16 / 9}
                    modalOk={
                      <>
                        <SaveOutlined /> {this.props.t("save-button-label")}
                      </>
                    }
                    modalCancel={this.props.t("cancel-button-label")}
                    modalTitle={this.props.t(
                      "companyadmin-design-cropimage-title"
                    )}
                    fillColor={this.props.company[0]?.primaryColor}
                  >
                    <Upload
                      name="background"
                      listType="picture-card"
                      fileList={this.state.background}
                      showUploadList={false}
                      onRemove={this.removeFileBackground}
                      accept=".png,.jpg,.jpeg,.gif"
                      onPreview={this.onPreview}
                      beforeUpload={ () => false }
                      onChange={ async ( evt ) => {
                        console.log("background upload Onchange event", evt);
                        if (evt.file) {
                          this.setState( { backgroundCropData: { ...this.state.backgroundCropData, image: evt.file } } );
                          await this.cropImage( this.state.backgroundCropData );
                          this.setState( { background: [ new File( [ this.props.image ], this.getFileName( this.props.image.type ), { type: this.props.image.type } ) ] } );
                        }
                      } }
                    >
                      {this.state.background.length < 1 ? (
                        !this.state.company.background ? (
                          <React.Fragment>
                            <PlusOutlined />
                            <div>
                              {this.props.t("companyadmin-design-upload")}
                            </div>
                          </React.Fragment>
                        ) : (
                          <img
                            src={`${
                              process.env.REACT_APP_BASE_URL
                            }/api/1.0.0/company/background/${
                              this.state.company.background
                            }?token=${
                              this.props.token.split(" ")[1]
                            }&date=${Date.now()}`}
                            alt="background"
                            width={100}
                          />
                        )
                      ) : (
                        <img
                          src={URL.createObjectURL(this.state.background[0])}
                          alt="background"
                          width={100}
                        />
                      )}
                    </Upload>
                  </ImgCrop>
                  <div style={{ color: "red" }}>
                    {this.props.t("companyadmin-design-maxsize-background")}
                  </div>
                </Form.Item>
            </Col>
            <Col span={16}>
              <AppPreview
                screenWidth="700px"
                screenHeight="525px"
                width="770px"
                height="579px"
                bottom="-11px"
                flagpaddings={
                  this.state.browser === "safari"
                    ? ["52px 5px 15px", "15px 5px 15px", "15px 5px 51px"]
                    : ["52px 5px 15px", "15px 5px 15px", "15px 5px 52px"]
                }
                paddingBottoms={
                  this.state.browser === "safari"
                    ? ["32px", "18px"]
                    : ["30px", "16px"]
                }
                background={
                  this.state.background.length > 0
                    ? URL.createObjectURL(this.state.background[0])
                    : this.props.user.companyId !== null && this.props.user.companyId !== undefined
                    ? `${process.env.REACT_APP_BASE_URL}/api/1.0.0/company/background/${this.props.company[0]?.background}`
                    : `${process.env.REACT_APP_BASE_URL}/api/1.0.0/company/background/${this.props.office[0]?.background}`
                }
                logo={
                  this.state.logo.length > 0
                    ? URL.createObjectURL(this.state.logo[0])
                    : this.props.user.companyId !== null && this.props.user.companyId !== undefined
                    ? `${process.env.REACT_APP_BASE_URL}/api/1.0.0/company/logo/${this.props.company[0]?.logoName}`
                    : `${process.env.REACT_APP_BASE_URL}/api/1.0.0/company/logo/${this.props.office[0]?.logoName}`
                }
                defaultLanguageCode={this.state.company?.defaultLanguage?.code}
                backgroundColor={this.state.primaryColor}
                welcomeTitle={
                  this.state.company?.companyTexts?.filter(
                    (text) =>
                      text.textId === 2 &&
                      text.language.code === i18n.language.toUpperCase()
                  )[0]?.textValue
                }
                welcomeText={
                  this.state.company?.companyTexts?.filter(
                    (text) =>
                      text.textId === 1 &&
                      text.language.code === i18n.language.toUpperCase()
                  )[0]?.textValue
                }
                logoBottom={"48px"}
              />
            </Col>
          </Row>
          <Row>
          {!this.props.companyId && (
            <CompanyLanguages
              companyId={this.props.companyId}
              officeId={this.props.office[0]?.id}
            />
          )}
          </Row>
          <Row>
          <Col style={{ float: "right", marginLeft: "auto"}}>
            <Form.Item style={{ textAlign: "center"}}>
                    <Button
                      htmlType="submit"
                      type="primary"
                      style={{ margin: "1vh" }}
                    >
                      <SaveOutlined /> {this.props.t("save-button-label")}
                    </Button>
            </Form.Item>
          </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  saveStatus: state.companyReducer.saveStatus,
  user: state.loginReducer.user,
  token: state.loginReducer.token,
  company: state.companyReducer.company,
  status: state.companyReducer.status,
  officeSaveStatus: state.officeReducer.saveStatus,
  officeStatus: state.officeReducer.status,
  office: state.officeReducer.office,
  selectedLanguageText: state.languageReducer.selectedLanguageText,
  image: state.companyReducer.image
});
const CompanyDesign = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedCompanyDesign)
);
export default CompanyDesign;
